<div class="d-flex w-100 mt-2 pb-2 border-bottom border-dark">
  <a class="mr-2 align-self-center" (click)="onLogoClicked($event)">
    <img
      src="{{ assetPath }}/images/logo-bg2-standard.svg"
      alt="BankingGuide"
      class="bg-logo"
      width="200"
      height="50"
    />
  </a>
  <div class="d-flex flex-grow-1">
    <div class="d-flex flex-column">
      <nav
        mat-tab-nav-bar
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        [tabPanel]="tabPanel"
        class="top-bar vr-text-regular"
        [style.width.px]="maxNavBarWidth"
      >
        <a
          mat-tab-link
          mat-align-tabs="left"
          *ngFor="let topic of menuConfig; index as i"
          [active]="activeTopic === topic.name"
          (click)="onTopicClicked(topic.name)"
        >
          {{ topic.displayname }}
        </a>
      </nav>
      <div #subNav style="margin-top: 2px">
        <div *ngFor="let topic of menuConfig">
          <nav
            *ngIf="activeTopic === topic.name && activeEntities"
            mat-tab-nav-bar
            mat-stretch-tabs="false"
            mat-align-tabs="start"
            [tabPanel]="tabPanelSub"
            [style.width.px]="maxNavBarWidth"
          >
            <a
              mat-tab-link
              class="subtopic"
              *ngFor="let entity of activeEntities; let first = first; let last = last"
              (click)="onEntityClicked(entity)"
              [disabled]="isLoading"
              [active]="entity.active"
              [ngClass]="{ 'first-subtopic': first, 'last-subtopic': last }"
            >
              {{ getPluralTitle(entity.name) }}
            </a>
          </nav>
        </div>
      </div>
    </div>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    <mat-tab-nav-panel #tabPanelSub></mat-tab-nav-panel>
  </div>

  <div class="d-flex align-items-center">
    <div class="d-flex" style="z-index: 99">
      <ng-container *ngIf="currentContext !== 'hub' && instances.length > 0">
        <input hidden #customisationUpload type="file" (change)="uploadCustomization($event)" accept=".xlsx" />
        <ng-container *ngIf="wideEnoughForButtons; else showMoreButtonCA">
          <div class="mr-3 d-flex">
            <vr-button
              *ngIf="isTestButtonShown"
              [label]="'Individualisierung'"
              (click)="handleCustomization()"
              [preset]="'small'"
            ></vr-button>
          </div>
          <div class="mr-3 d-flex" *ngIf="isTestButtonShown">
            <vr-button [label]="'Testberatungen'" (click)="navigateToTest()" [preset]="'small'"></vr-button>
          </div>
          <div class="mr-3 d-flex" *ngIf="isGraphButtonShown">
            <vr-button [label]="'Grafische Ansicht'" (click)="navigateToGraph()" [preset]="'small'"></vr-button>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ng-container *ngIf="currentContext === 'hub'">
      <ng-container *ngIf="wideEnoughForButtons; else showMoreButtonHub">
        <vr-button
          class="mr-3"
          [preset]="'small'"
          [label]="'Inhalt zur�cksetzen'"
          (click)="onResetHub($event)"
        ></vr-button>
        <vr-button
          *ngIf="isLicenseButtonShown"
          class="mr-3"
          [preset]="'small'"
          [label]="'Lizenz aktualisieren'"
          (click)="onUpdateLicense($event)"
        ></vr-button>
      </ng-container>
    </ng-container>
    <div class="d-flex mr-3 align-items-center">
      <div
        *ngIf="currentContext === 'hub'"
        class="mx-2 vr-text-small"
        [style.color]="color.find(color.name.Neutral700)"
      >
        User Id: {{ currentTenantId }}
      </div>
      <mat-form-field *ngIf="currentContext !== 'hub'" class="mx-2">
        <mat-label>
          <div>Instanz Id (User Id: {{ currentTenantId }})</div>
        </mat-label>
        <mat-select
          [placeholder]="'Instance Id'"
          [disabled]="instances.length === 0"
          [value]="instances.length !== 0 ? currentInstanceId : 'noId'"
          (selectionChange)="onInstanceChange($event)"
        >
          <mat-option *ngIf="instances.length === 0" [value]="'noId'">Keine Instanzen </mat-option>
          <mat-option *ngFor="let instance of instances" [value]="instance.id"
            >{{ instance.name }} ({{ instance.status }})</mat-option
          ></mat-select
        ></mat-form-field
      >
    </div>
    <div class="d-flex align-items-center px-2">
      <nav
        mat-tab-nav-bar
        mat-stretch-tabs="false"
        mat-align-tabs="start"
        [tabPanel]="tabPanelSwitchContext"
        class="top-bar vr-text-regular mr-2 endpoint"
      >
        <nav
          class="endpoint"
          mat-tab-link
          (click)="onSwitchContext(contextEnum.configApp)"
          [active]="activeEndpoint === contextEnum.configApp"
          [disabled]="isLoading || instances.length === 0"
        >
          Instanzen
        </nav>
        <nav
          class="endpoint"
          mat-tab-link
          (click)="onSwitchContext(contextEnum.bankHub)"
          [active]="activeEndpoint === contextEnum.bankHub"
          [disabled]="isLoading"
        >
          Grundeinstellungen
        </nav>
      </nav>
      <sticky-button></sticky-button>
      <div *ngIf="platform === 'aws'" class="d-flex">
        <vr-button
          [preset]="'small'"
          [label]="'Log out'"
          [noMinWidth]="true"
          [buttonColor]="color.find(color.name.Neutral200)"
          (click)="onLogoutClicked($event)"
        ></vr-button>
      </div>
      <mat-tab-nav-panel #tabPanelSwitchContext></mat-tab-nav-panel>
    </div>
  </div>
</div>

<ng-template #showMoreButtonCA>
  <vr-button
    *ngIf="instances.length > 0"
    class="mr-2"
    [preset]="'small'"
    [icon]="buttonIcon.more"
    [label]="'Mehr'"
    [noMinWidth]="true"
    [textColor]="color.find(color.name.Neutral700)"
    (click)="moreOptionsDialogCA($event)"
  >
  </vr-button>
</ng-template>

<ng-template #showMoreButtonHub>
  <vr-button
    class="mr-2"
    [preset]="'small'"
    [icon]="buttonIcon.more"
    [label]="'Mehr'"
    [noMinWidth]="true"
    [textColor]="color.find(color.name.Neutral700)"
    (click)="moreOptionsDialogHub($event)"
  >
  </vr-button>
</ng-template>
